export const DEFAULT_MEDIA_CONSTRAINTS = {
  audio: true,
  video: { width: 300, height: 300, facingMode: 'user' },
}

export const MEDIASOUP_SOCKET_ENDPOINT =
  process.env.WEBSOCKET_MEDIASOUP_URL || 'http://localhost:8000/mediasoup'
// export const MEDIASOUP_SOCKET_ENDPOINT =
//   'https://api.bridgehealth.care/mediasoup'

export const CHAT_SOCKET_ENDPOINT =
  process.env.CHAT_SOCKET_ENDPOINT || 'http://localhost:8000/chat'
// export const CHAT_SOCKET_ENDPOINT = 'https://api.bridgehealth.care/chat'
